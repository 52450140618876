<template>
    <div class='tw-w-full'>
        <Card class='p-shadow-5'>
            <template #content>
            
            
                <div class='tw-w-full tw-flex tw-flex-col md:tw-flex-row tw-items-center'>
                    <div class='tw-flex tw-flex-col tw-text-left tw-px-2'>
                        
                        <div class=''>
                            <span style='color:var(--primary-color)'><strong>{{fecha}}</strong></span>
                        </div>
                        <div>
                            <span style='text-transform:capitalize;'><strong>Dr. {{medico.toLowerCase()}}</strong></span>
                        </div>
                        <div>
                            <span style='text-transform:capitalize;'>{{especialidad.toLowerCase()}}</span>
                        </div>


                    </div>
                    <div class='md:tw-ml-auto tw-mt-4 md:tw-mt-0 md:tw-space-y-1'>
                        <div v-if='isDescargable' class='tw-flex tw-flex-col tw-items-center tw-justify-center'>
                            <div @click='$emit("descargar")' class='tw-select-none tw-cursor-pointer tw-border tw-p-1 tw-rounded tw-flex tw-items-center tw-justify-between'>
                                <span style='color:var(--primary-color)'><strong>Descargar</strong></span>
                                <i style='font-size:1.8rem;color:var(--primary-color)' class='pi pi-file-pdf'></i>
                            </div>
                            <div>
                                <span style='color:var(--primary-color)'><strong>Venc.: {{fechaVencimiento}}</strong></span>
                            </div>
                        </div>
                        <div v-else class='tw-select-none tw-border tw-border-red-500  tw-p-1 tw-rounded tw-flex tw-items-center tw-justify-between tw-px-2'>
                            <span style='color:rgb(239, 68, 68)'><strong>Vencida</strong></span>
                        </div>
                    </div>

                </div>
            
            </template>

            

        </Card>
    </div>

</template>

<script lang='ts'>
import { defineComponent, ref,computed } from "@vue/runtime-core";
import moment from 'moment';
export default defineComponent({
    props:['dato'],
    setup(props){
        
        const es = moment(props.dato.datos.fecha).locale('es')
        const medico = ref(props.dato.medico.nombre);
        const especialidad = ref(props.dato.especialidad.nombre);
        const fecha = ref(es.format('dddd DD \\d\\e MMM \\d\\e\\l YYYY'))
        const isDescargable = computed(()=>{
            return moment().diff(es,'days') <= 30
        })
        const fechaVencimiento = computed(()=>{
            return es.add(30,'days').format('DD/MM/YYYY')
        })

        return {
            medico,
            especialidad,
            fecha,
            fechaVencimiento,
            isDescargable,
        }
    }

})
</script>

<style>

</style>